import api from "../http/index";

export default class StoreService{

    static async createOrder(data){
        const order = data.order
        const customer = data.customer
        const response = await api.post("/order", {order, customer});
        return response;
    }

    static async getStoreServices(){
        const response = await api.get('/getservices')
        return response
    }
}