import React, {useCallback, useEffect, useState} from 'react';
import './Form.css';
import {useTelegram} from "../../hooks/useTelegram";
import { useStoreContext } from '../../context/store';
import Basket from '../Basket/Basket';
import StoreService from '../../service/storeService'
import Button from '../Button/Button';
import { useMemo } from 'react';


const Form = () => {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const {tg} = useTelegram();

    const {dataOrder, dataCustomer} = useStoreContext()
    
    const onSendData = useCallback(async () => {
        const order = dataOrder
        const customer = {
            name,
            address,
            phone,
        }
        const data = {
            order,
            customer
        }
        const response = await StoreService.createOrder(data)
        // console.log(response)
        // fetch('http://79.143.30.116:8000/order', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data)
           
        // })
    }, [name, address, phone])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Отправить данные'
        })
    }, [])

    useEffect(() => {
        if(!name || !phone || !address) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
            tg.MainButton.setParams({
                color: '#00c38a',
                text: `Оформить заказ на ${dataOrder.totalPrice}р.`
                
            })
        }
    }, [name, phone, address, {...dataOrder}])

    const onChangeName = (e) => {
        setName(e.target.value)
    }

    const onChangeAddress = (e) => {
        setAddress(e.target.value)
    }

    const onChangePhone = (e) => {
        setPhone(e.target.value)
    }

    return (
        <div className={"form"}>
            <Basket />
            <h3>Введите ваши данные</h3>
            <input
                className={'input'}
                type="text"
                placeholder={'Как к вам обращаться?'}
                value={name}
                onChange={onChangeName}
            />
              <input
                className={'input'}
                type="text"
                placeholder={'Адрес для проведения работ'}
                value={address}
                onChange={onChangeAddress}
            />
            <input
                className={'input'}
                type="phone"
                placeholder={'Ваш телефон'}
                value={phone}
                onChange={onChangePhone}
            />
                        {/* <Button className={'add-btn'} onClick={onSendData}>test</Button> */}
        </div>
    );
};

export default Form;
