import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL } from '../../http';
import Button from "../Button/Button";
import './ProductItem.css';

const ProductItem = ({product, className, onAdd, basket, id, openProduct}) => {
    const [selected, setSelected] = useState(false)

    const onAddHandler = (e) => {
        e.stopPropagation()
        onAdd(product);
    }

    const openProductHandler = () => {
        openProduct(product)
    }
    // console.log(basket)
    function selectedItem() {
        if(basket.map(item => item.id).includes(id)){
            setSelected(true)
        } else {
            setSelected(false)
        }
    }

    useEffect(() =>{
        selectedItem()
        
    }, [basket])

    return (
        <div className={'container '  + className}>
            <div className={'product ' + className} onClick={openProductHandler}>
                <div className={'content-container'}>
                    <div className={'imgContainer'}>
                        <img src={`${API_URL}/image/${product.image}.jpg`} alt={product.image} />
                    </div>
                    <div className={'content-container__text'}>
                        <div className={'title'}>{product.title}</div>
                        {/* <div className={'description'}>{product.description}</div> */}
                        <div className={'price'}>
                        {`от ${product.price} руб.`}
                        </div>
                    </div>
                </div>
                <Button className={`add-btn ${selected ? 'selected' : ''}`} onClick={onAddHandler}>
                    {selected ? 'Убрать из корзины' : 'Добавить в корзину'}
                </Button>
            </div>
        </div>
    );
};

export default ProductItem;
