import React, {useState} from 'react';
import './Subcategories.css';
import ProductItem from "../../components/ProductItem/ProductItem";
import {useTelegram} from "../../hooks/useTelegram";
import {useCallback, useEffect} from "react";
import Images from '../../static/image'
import { Route, Router, useLocation, useNavigate, useParams } from "react-router-dom";
import { useStoreContext } from '../../context/store';



const images = new Images()

const getTotalPrice = (items = []) => {
    return items.reduce((acc, item) => {
        return acc += item.price
    }, 0)
}

const Subcategories = () => {
    const [categoryId, setCategoryId] = useState(0)
    const [title, setTitle] = useState('')
    const [subCategories, setSubCategories] = useState([])
    const [addedItems, setAddedItems] = useState([]);
    const {tg, queryId} = useTelegram();
    const navigate = useNavigate()
    const {dataOrder, setDataOrder, isMainPage, setIsMainPage, servicesList} = useStoreContext()
    console.log(dataOrder)
    const router = useParams()

    const onSendData = useCallback(() => {
        const data = {
            products: addedItems,
            totalPrice: getTotalPrice(addedItems),
            queryId,
        }
        setDataOrder(data)
        setIsMainPage(false)
        navigate("/form")
    }, [addedItems])

    // async function getServiceList(){
    //         const response = await StoreService.getStoreServices()
    //         setServiceList(response.data)
    // }
    
    function openSubcategories(product){
        console.log(product)
    }

    const onAdd = (product) => {
        const alreadyAdded = addedItems.find(item => item.id === product.id);
        let newItems = [];
        console.log(alreadyAdded)
        console.log(product)
        console.log(addedItems)
        if(alreadyAdded) {
            newItems = addedItems.filter(item => item.id !== product.id);
        } else {
            newItems = [...addedItems, product];
        }

        setAddedItems(newItems)
        console.log(newItems)

        if(newItems.length === 0) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
            tg.MainButton.setParams({
                color: '#00c38a',
                text: `Перейти в корзину: ${getTotalPrice(newItems)}р.`
                
            })
        }
    }
    
    function getSubcategories(){
        console.log(router)

        setCategoryId(router.id)
        let arrSubCategories= []
        servicesList.categories?.map(item => {
            if(item.id == router.id){
                arrSubCategories = [...item.subcategories]
                setTitle(item.title)
            }
        console.log(arrSubCategories)
        })
        setSubCategories(servicesList.subcategories?.filter(item => arrSubCategories.includes(item.id)))
        console.log(servicesList.subcategories)
    }

    useEffect(() => {
        getSubcategories()
    }, [servicesList])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        if(dataOrder.products){
            setAddedItems(dataOrder.products)
            if(dataOrder.products.length !== 0){
                tg.MainButton.show();
                tg.MainButton.setParams({
                    color: '#00c38a',
                    text: `Перейти в корзину: ${dataOrder.totalPrice}р.`
                    
                })
            }
        }
    }, [])
    console.log(subCategories)

    return (
        <div className={'list'}>
            <div className={'title-subcategory'}>
                {title}
            </div>
            {subCategories?.map((item, i) => {
            if(i % 2 === 0) {       
                return    <div className='itemConatainer'> 
                        <ProductItem
                            key={item.id}
                            id={item.id}
                            basket={addedItems}
                            product={item}
                            openProduct={openSubcategories}
                            onAdd={onAdd}
                            className={`item ${subCategories.length-1 === i && subCategories.length % 2 === 1 ? 'last' : ''}`}
                        />
                        {subCategories[i+1] &&
                            
                        
                        <ProductItem
                            key={subCategories[i+1]?.id}
                            id={subCategories[i+1]?.id}
                            basket={addedItems}
                            product={subCategories[i+1]}
                            openProduct={openSubcategories}
                            onAdd={onAdd}
                            className={`item ${subCategories.length-1 === i && subCategories.length % 2 === 1 ? 'last' : ''}`}
                        />
                    }
                    </div>
                } 
            })}
            {/* <Button className={'add-btn'} onClick={onSendData}>test</Button> */}
        </div>
    );
};

export default Subcategories;
