import React, {useCallback, useEffect, useState} from 'react';
import './FeedbackForm.css';
import {useTelegram} from "../../hooks/useTelegram";
import { useStoreContext } from '../../context/store';

const FeedbackForm = () => {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [report, setReport] = useState('')
    const {tg} = useTelegram();

    const {dataOrder, dataCustomer} = useStoreContext()
    console.log(dataOrder)
    console.log(dataCustomer)

    const onSendData = useCallback(() => {
        const data = {
            name,
            address,
            phone,
            report,
        }
        tg.sendData(JSON.stringify(data));
    }, [name, address, phone])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Отправить данные'
        })
    }, [])

    useEffect(() => {
        if(!name || !phone || !address) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }, [name, phone, address])

    const onChangeName = (e) => {
        setName(e.target.value)
    }

    const onChangeAddress = (e) => {
        setAddress(e.target.value)
    }

    const onChangePhone = (e) => {
        setPhone(e.target.value)
    }

    const onChangeReport = (e) => {
        setReport(e.target.value)
    }

    return (
        <div className={"form"}>
            <h3>Введите ваши данные</h3>
            <input
                className={'input'}
                type="text"
                placeholder={'Как к вам обращаться?'}
                value={name}
                onChange={onChangeName}
            />
              <input
                className={'input'}
                type="text"
                placeholder={'Адрес для проведения работ'}
                value={address}
                onChange={onChangeAddress}
            />
            <input
                className={'input'}
                type="phone"
                placeholder={'Ваш телефон'}
                value={phone}
                onChange={onChangePhone}
            />
              <input
                className={'input'}
                type="text"
                placeholder={'Адрес для проведения работ'}
                value={report}
                onChange={onChangeReport}
            />
        </div>
    );
};

export default FeedbackForm;
