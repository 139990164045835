import React, {useState} from 'react';
import './Categories.css';
import ProductItem from "../ProductItem/ProductItem";
import {useTelegram} from "../../hooks/useTelegram";
import {useCallback, useEffect} from "react";
import Images from '../../static/image'
import { useLocation, useNavigate } from "react-router-dom";
import { useStoreContext } from '../../context/store';
import Button from '../Button/Button';
import StoreService from '../../service/storeService';


const images = new Images()

const getTotalPrice = (items = []) => {
    return items.reduce((acc, item) => {
        return acc += item.price
    }, 0)
}

const Categories = () => {
    // const [serviceList, setServiceList] = useState({})
    const [categories, setCategories] = useState([])
    const [services, setSetvices] = useState([])
    const [addedItems, setAddedItems] = useState([]);
    const {tg, queryId} = useTelegram();
    const navigate = useNavigate()
    const {dataOrder, setDataOrder, isMainPage, setIsMainPage, servicesList, setServicesList} = useStoreContext()
    console.log(dataOrder)

    const onSendData = useCallback(() => {
        const data = {
            products: addedItems,
            totalPrice: getTotalPrice(addedItems),
            queryId,
        }
        setDataOrder(data)
        setIsMainPage(false)
        navigate("/form")
    }, [addedItems])

    // async function getServiceList(){ 
    //         const newList = {...servicesList}
    //         setServiceList(newList)
    //         const response = await StoreService.getStoreServices()
    //         setServiceList(response.data)
    // }
    
    function openSubcategories(product){
        const data = {
            products: addedItems,
            totalPrice: getTotalPrice(addedItems),
            queryId,
        }
        setDataOrder(data)
        setIsMainPage(false)
        navigate(`/${product.id}`)
        console.log(product)
    }

    const onAdd = (product) => {
        const alreadyAdded = addedItems.find(item => item.id === product.id);
        let newItems = [];
        console.log(alreadyAdded)
        console.log(product)
        console.log(addedItems)
        if(alreadyAdded) {
            newItems = addedItems.filter(item => item.id !== product.id);
        } else {
            newItems = [...addedItems, product];
        }

        setAddedItems(newItems)
        console.log(newItems)

        if(newItems.length === 0) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
            tg.MainButton.setParams({
                color: '#00c38a',
                text: `Перейти в корзину: ${getTotalPrice(newItems)}р.`
                
            })
        }
    }

    useEffect(() => {
        setCategories(servicesList.categories)
    }, [servicesList])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        if(dataOrder.products){
            setAddedItems(dataOrder.products)
            if(dataOrder.products.length !== 0){
                tg.MainButton.show();
                tg.MainButton.setParams({
                    color: '#00c38a',
                    text: `Перейти в корзину: ${dataOrder.totalPrice}р.`
                    
                })
            }
        }
      
        // getServiceList()
    }, [servicesList])


    return (
        <div className={'list'}>
            {categories?.map((item, i) => {
                    if(i % 2 === 0) {       
                    return    <div className='itemConatainer'> 
                            <ProductItem
                                key={item.id}
                                id={item.id}
                                basket={addedItems}
                                product={item}
                                openProduct={openSubcategories}
                                onAdd={onAdd}
                                className={`item ${categories.length-1 === i && categories.length % 2 === 1 ? 'last' : ''}`}
                            />
                            {categories[i+1] &&
                                
                            
                            <ProductItem
                                key={categories[i+1]?.id}
                                id={categories[i+1]?.id}
                                basket={addedItems}
                                product={categories[i+1]}
                                openProduct={openSubcategories}
                                onAdd={onAdd}
                                className={`item ${categories.length-1 === i && categories.length % 2 === 1 ? 'last' : ''}`}
                            />
                        }
                        </div>
                    } 
            })}
            {/* <Button className={'add-btn'} onClick={onSendData}>test</Button> */}
        </div>
    );
};

export default Categories;
