import regular_service from '../static/images/regular_service.jpg'
import doors_locks from '../static/images/doors_locks.jpg'
import furniture from '../static/images/furniture.jpg'
import plumber from '../static/images/plumber.jpg'
import electronic from '../static/images/electronic.jpg'
import household from '../static/images/household.jpg'
import air_conditioning from '../static/images/air_conditioning.jpg'
import computer_assist from '../static/images/computer_assist.jpg'
import window from '../static/images/window.jpg'
import finishing_work from '../static/images/finishing_work.jpg'
import painting_work from '../static/images/painting_work.jpg'
import cleaning from '../static/images/cleaning.jpg'
import utilization from '../static/images/utilization.jpg'
import technical_assessment_apartment from '../static/images/technical_assessment_apartment.jpg'
import building from '../static/images/building.jpg'

class Images{
    categories = {
        regular_service:{
            img: regular_service,
            alt: 'regular_service',
        },
        doors_locks:{
            img: doors_locks,
            alt: 'doors_locks',
        },
        furniture:{
            img: furniture,
            alt: 'furniture',
        },
        plumber:{
            img: plumber,
            alt: 'plumber',
        },
        electronic:{
            img: electronic,
            alt: 'electronic',
        },
        household:{
            img: household,
            alt: 'household',
        },
        air_conditioning:{
            img: air_conditioning,
            alt: 'air_conditioning',
        },
        computer_assist:{
            img: computer_assist,
            alt: 'computer_assist',
        },
        window:{
            img: window,
            alt: 'window',
        },
        finishing_work:{
            img: finishing_work,
            alt: 'finishing_work',
        },
        painting_work:{
            img: painting_work,
            alt: 'painting_work',
        },
        cleaning:{
            img: cleaning,
            alt: 'cleaning',
        },
        utilization:{
            img: utilization,
            alt: 'utilization',
        },
        technical_assessment_apartment:{
            img: technical_assessment_apartment,
            alt: 'technical_assessment_apartment',
        },
        building:{
            img: building,
            alt: 'building',
        },
    }
}

export default Images