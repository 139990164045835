import axios from "axios";

export const API_URL = "https://back.dev.shop.tg.rn86.ru";
// export const API_URL = "http://localhost:8000";

const api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

export default api;