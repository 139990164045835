import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useStoreContext } from '../../context/store';
import Button from "../Button/Button";
import deleteIcon from '../../static/icons/trash-outline.svg'
import './Basket.css';
import { API_URL } from '../../http';

const Basket = () => {
    const [products, setProducts] = useState([])
    const {dataOrder, setDataOrder} = useStoreContext()

    function deleteitem (id, price) {
        const newOrder = {...dataOrder}
        const newProducts = products?.filter(item => item.id !== id)
        setProducts(newProducts)
        newOrder.totalPrice -= price
        newOrder.products = newProducts
        setDataOrder(newOrder)

    }

    useEffect(() =>{
        setProducts(dataOrder.products)
    }, [])

    return (
        <div className={'backet-container'}>
            <h3>Ваш заказ</h3>
            {dataOrder.products?.map((item)=>
                <div key={Math.random()} className={'backet-item'}>
                    <>
                        <div className={'backet-item__imgContainer'}>
                            <img src={`${API_URL}/image/${item.image}.jpg`} alt={'product'} />
                        </div>
                        <div className={'backet-item__contentWrapper'}>
                            <div className={'backet-item__title'}>{item.title}</div>
                            <div className={'backet-item__price'}>
                                <span><b>{`от ${item.price} руб.`}</b></span>
                            </div>
                        </div>
                        
                    </>
                    <Button className={`backet-item__del-btn`} onClick={() => deleteitem(item.id, item.price)}>
                        <img className={'backet-item__deleteIcon'} src={deleteIcon} alt='icon'/>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Basket;
