import './App.css';
import {useEffect, useState} from "react";
import {useTelegram} from "./hooks/useTelegram";
import Header from "./components/Header/Header";
import {Route, Routes, useLocation} from 'react-router-dom'
import ProductList from "./components/ProductList/ProductList";
import Form from "./components/Form/Form";
import FeedbackForm from "./components/FeedbackForm/FeedbackForm";
import { StoreContextProvider } from './context/store';
import Categories from './components/categories/Categories';
import StoreService from './service/storeService';
import Subcategories from './pages/Subcategories/Subcategories';

function App() {
    const [dataOrder, setDataOrder] = useState({})
    const [dataCustomer, setDataCustomer] = useState({})
    const [servicesList, setServicesList] = useState({})
    const [isMainPage, setIsMainPage] = useState(true)
    const {tg} = useTelegram();

    const location = useLocation()

    async function getServiceList(){
        const response = await StoreService.getStoreServices()
        setServicesList(response.data)
}

    useEffect(() => {
        tg.ready();
        
        if(location.pathname !== '/'){      
            setIsMainPage(false)
        }
        getServiceList()
    }, [])

    return (
        <StoreContextProvider value={{
            dataOrder,
            setDataOrder,
            dataCustomer,
            setDataCustomer,
            isMainPage,
            setIsMainPage,
            servicesList,
            setServicesList
        }}>
            <div className="App">
                <Header />
                <Routes>
                    <Route index element={<Categories />}/>
                    <Route path={'/:id'} element={<Subcategories />}/>
                    <Route path={'form'} element={<Form />}/>
                    <Route path={'feedback'} element={<FeedbackForm />}/>
                </Routes>
            </div>
        </StoreContextProvider>
    );
}

export default App;
