import React from 'react';
import Button from "../Button/Button";
import {useTelegram} from "../../hooks/useTelegram";
import './Header.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStoreContext } from '../../context/store';
import { useEffect } from 'react';

const Header = () => {
    const {user, onClose} = useTelegram();
    const {isMainPage, setIsMainPage} = useStoreContext()
    const navigate = useNavigate()
    const location = useLocation();

  

    const onBack = () =>{
        setIsMainPage(true)
        navigate(-1)
        // navigate("/")
    } 

    useEffect(() => {

    }, [])

    return (
        <div className={`header ${isMainPage || location.pathname === '/feedback' ? 'hidden' : ''}`}>
            <Button onClick={isMainPage || location.pathname === '/feedback' ? onClose : onBack}>{isMainPage || location.pathname === '/feedback' ? 'Закрыть' : 'Назад'}</Button>
            <span className={'username'}>
                {user?.username}
            </span>
        </div>
    );
};

export default Header;
