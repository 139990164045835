import React, {useState} from 'react';
import './ProductList.css';
import ProductItem from "../ProductItem/ProductItem";
import {useTelegram} from "../../hooks/useTelegram";
import {useCallback, useEffect} from "react";
import Images from '../../static/image'
import { useLocation, useNavigate } from "react-router-dom";
import { useStoreContext } from '../../context/store';
import Button from '../Button/Button';
import StoreService from '../../service/storeService';


const images = new Images()


// const products = [
//     {id: '1', title: 'Подписка на регулярное обслуживание', price: 3000, category: 'subscription', service: [], description: '', image: images.categories.regular_service},
//     {id: '2', title: 'Двери, замки и фурнитура', price: 1050, category: 'doors', service: [], description: '', image:  images.categories.doors_locks},
//     {id: '3', title: 'Мебель, предметы интерьера- сборка и монтаж', price: 1300, category: 'furniture', service: [], description: '', image: images.categories.furniture},
//     {id: '4', title: 'Сантехнические работы', price: 1000, category: 'plumber', service: [], description: '', image: images.categories.plumber},
//     {id: '5', title: 'Электромонтажные работы', price: 850, category: 'wiring', service: [], description: '', image: images.categories.electronic},
//     {id: '6', title: 'Бытовая техника- установка и ремонт', price: 1800, category: 'household', service: [], description: '', image: images.categories.household},
//     {id: '7', title: 'Кондиционер- установка, ремонт и обслуживание', price: 3300, category: 'air_conditioning', service: [], description: '', image: images.categories.air_conditioning},
//     {id: '9', title: 'Помощь с компьютером и гаджетами', price: 1300, category: 'computer', service: [], description: '', image: images.categories.computer_assist},
//     {id: '10', title: 'Окна, откосы- установка, ремонт', price: 1200, category: 'window', service: [], description: '', image: images.categories.window},
//     {id: '11', title: 'Отделочные работы', price: 850, category: 'finishing', service: [], description: '', image: images.categories.finishing_work},
//     {id: '12', title: 'Покрасочные работы', price: 920, category: 'painting', service: [], description: '', image: images.categories.painting_work},
//     {id: '13', title: 'Уборка', price: 1050, category: 'cleaning', service: [], description: '', image: images.categories.cleaning},
//     {id: '14', title: 'Вывоз крупного мусора, утилизация', category: 'utilization', service: [], price: 1150, description: '', image: images.categories.utilization},
//     {id: '15', title: 'Техническая оценка квартиры', category: 'assassment', service: [], price: 3800, description: '', image: images.categories.technical_assessment_apartment},
//     {id: '16', title: 'Строительные работы', price: 910, category: 'building', service: [], description: '', image: images.categories.building},
// ]

const getTotalPrice = (items = []) => {
    return items.reduce((acc, item) => {
        return acc += item.price
    }, 0)
}

const ProductList = () => {
    const [productList, setProductList] = useState({})
    const [catigories, setCatigories] = useState([])
    const [subCatigories, setSubCatigories] = useState([])
    const [services, setSetvices] = useState([])
    const [addedItems, setAddedItems] = useState([]);
    const {tg, queryId} = useTelegram();
    const navigate = useNavigate()
    const {dataOrder, setDataOrder, isMainPage, setIsMainPage} = useStoreContext()
    console.log(dataOrder)

    const onSendData = useCallback(() => {
        const data = {
            products: addedItems,
            totalPrice: getTotalPrice(addedItems),
            queryId,
        }
        setDataOrder(data)
        setIsMainPage(false)
        navigate("/form")
    }, [addedItems])

    async function getProductList(){
            const response = await StoreService.getStoreServices()
            setProductList(response.data)
    }

    const onAdd = (product) => {
        const alreadyAdded = addedItems.find(item => item.id === product.id);
        let newItems = [];
        console.log(alreadyAdded)
        console.log(product)
        console.log(addedItems)
        if(alreadyAdded) {
            newItems = addedItems.filter(item => item.id !== product.id);
        } else {
            newItems = [...addedItems, product];
        }

        setAddedItems(newItems)
        console.log(newItems)

        if(newItems.length === 0) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
            tg.MainButton.setParams({
                color: '#00c38a',
                text: `Перейти в корзину: ${getTotalPrice(newItems)}р.`
                
            })
        }
    }

    useEffect(() => {
        setCatigories(productList.categories)
        setSubCatigories(productList.subCatigories)
        setSetvices(productList.services)
    }, [productList])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        if(dataOrder.products){
            setAddedItems(dataOrder.products)
            if(dataOrder.products.length !== 0){
                tg.MainButton.show();
                tg.MainButton.setParams({
                    color: '#00c38a',
                    text: `Перейти в корзину: ${dataOrder.totalPrice}р.`
                    
                })
            }
        }
        getProductList()
    }, [])


    return (
        <div className={'list'}>
            {catigories?.map((item, i) => (
                <ProductItem
                    key={item.id}
                    id={item.id}
                    basket={addedItems}
                    product={item}
                    onAdd={onAdd}
                    className={`item ${catigories.length-1 === i && catigories.length % 2 === 1 ? 'last' : ''}`}
                />
            ))}
            {/* <Button className={'add-btn'} onClick={onSendData}>test</Button> */}
        </div>
    );
};

export default ProductList;
